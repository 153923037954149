import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { loginUser, updatePassword } from "../../utils/api";
import Auth from "../../utils/auth";
import "./login.css";
import { Link } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  //otp password states
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather Data for query
    const formData = {
      email: email,
      password: password,
    };

    // Try to Log the User in
    try {
      const response = await loginUser(formData);

      if (!response.ok) {
        // Handle unsuccesfull login
        const { message } = await response.json();

        console.log(message);

        if (message === "Incorrect password for this account.") {
          setErrorMessage(
            "Your email or password is incorrect, please try again."
          );
        } else if (message === "There is no account found with this email.") {
          setErrorMessage("There is no account found with this email.");
        } else {
          setErrorMessage("Something has gone wrong, please try again.");
        }
      } else {
        // If successfull, save token and re-route to their "dashboard"
        const { token, user_type } = await response.json();
        Auth.login(token);

        if (user_type === "Super User") {
          navigate(`/admin`);
        } else if (user_type === "Agent") {
          navigate(`/agent-portal`);
        } else if (user_type === "Lender") {
          navigate(`/lender-portal`);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handlePasswordReset = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }

    try {
      //request to "/api/users/update-password"
      const response = await updatePassword({ email, otp, newPassword });

      if (response.ok) {
        setErrorMessage(
          "Password successfully updated. Please log in with your new password."
        );
        //hide change password inputs
        setShowChangePassword(false);
      } else {
        const { message } = await response.json();
        setErrorMessage(message || "Failed to update password.");
      }
    } catch (error) {
      console.error("There was an error updating the password:", error);
      setErrorMessage("An error occurred while updating the password.");
    }
  };
  return (
    <div>
      <Header />

      <div className="login-page">
        <h1>Welcome back!</h1>

        <p className="login-message">
          This login is for our existing Find a Home Agents and Lenders. If
          you’re new here, you can create a free agent account by visiting the{" "}
          <a href="/agent-signup">Agent Signup</a> page, or create a free lender
          account by visiting the <a href="/lender-signup">Lender Signup</a>{" "}
          page.
        </p>

        <form
          onSubmit={showChangePassword ? handlePasswordReset : handleFormSubmit}
        >
          {!showChangePassword ? (
            <>
              <div className="agent-input-group">
                <label htmlFor="email" className="agent-form-label">
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className="agent-form-input"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="agent-input-group">
                <label htmlFor="password" className="agent-form-label">
                  Password *
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  className="agent-form-input"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center gap-2 loans-mb-3 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms((prev) => !prev)}
                  className="form-check-input w-5 h-5 accent-blue-500 cursor-pointer"
                />
                <label
                  htmlFor="terms"
                  className="form-check-label text-gray-700 text-sm"
                >
                  I accept the{" "}
                  <Link
                    to={"/privacy"}
                    target="_blank"
                    className="text-blue-600 underline"
                  >
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <button
                className={`agent-btn ${!acceptedTerms ? "disabled-btn" : ""}`}
                type="submit"
                disabled={!acceptedTerms}
              >
                Login
              </button>

              {/* Display Error Message */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </>
          ) : (
            <>
              {/* Change password inputs */}
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <label htmlFor="confirmNewPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              <input
                type="submit"
                value="Change Password"
                aria-label="change-password-button"
              />
              {/* Display Error Message */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </>
          )}
        </form>
        <div className="forgot-password">
          {/* Forgot Password */}
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
